<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-img src="./../../assets/images/login.jpg" :style="{
      height:
        $vuetify.breakpoint.name == 'xs'
          ? '700px'
          : $vuetify.breakpoint.name == 'sm'
            ? '700px'
            : $vuetify.breakpoint.name == 'md'
              ? '100%'
              : $vuetify.breakpoint.name == 'lg'
                ? '100%'
                : '100%',
    }">
      <v-layout wrap justify-center>
        <v-flex xs12 pt-5>
          <v-layout wrap justify-center pt-16>
            <v-flex xs3 sm3 md2 lg2>
              <router-link to="/">
                <v-layout wrap justify-center>
                  <v-flex xs4 sm2 md2 lg1><v-img src="./../../assets/logoeco.png" contain height="25px"
                      width="25px"></v-img></v-flex>
                  <v-flex xs8 sm6 md6 lg5>
                    <span style="
                          font-family: pollerOneRegular;
                          font-size: 14px;
                          color: #000;
                        ">ECOLedger</span>
                  </v-flex>
                </v-layout>
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center py-10>
            <v-flex xs3 sm2 md1 text-center>
              <v-img contain height="100px" width="100px" src="./../../assets/icons/verify.png">
              </v-img>
            </v-flex>
            <v-flex xs12 sm12 py-5>
              <span style="font-family: montserratRegular; font-size: 20px">Welcome to ECOLedger !
              </span>
            </v-flex>
            <v-flex xs12 sm6>
              <span style="font-family: montserratRegular; font-size: 14px">Your email has been verified <br />
                Congratulations, your account has been activated!
                <br />
                <span style="
                      font-family: montserratBold;
                      font-size: 14px;
                      color: #6aafee;
                    "><router-link to="/loginForm">
                    Please click to login
                  </router-link></span>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
    };
  },
  beforeMount() {
    this.verify();
  },
  methods: {
    verify() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/email/verify",
        params: {
          id: this.$route.query.id,
          emailVerificationCode: this.$route.query.emailVerificationCode,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
